import axios from 'axios';

export const instance = axios.create({
  baseURL: 'https://api.anomi.app/admin',
});

export const setToken = token => {
  if (token) {
    return (instance.defaults.headers.authorization = `Bearer ${token}`);
  }
  instance.defaults.headers.authorization = '';
};

export const expertInstance = axios.create({
  baseURL: 'https://api.anomi.app',
});
