import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchLogsCombined = createAsyncThunk(
  'logs/combined/fetch',
  async (params, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/logs/combined');
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchLogsError = createAsyncThunk(
  'logs/error/fetch',
  async (params, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(
        'logs/error'
        // , {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
        // }
      );
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const postLogDownload = createAsyncThunk(
  'logDownload/post',
  async (name, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post('/logs/download', name, {
        headers: {
          Accept: 'application/octet-stream',
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const backupLogsPost = createAsyncThunk(
  'backupLogs/post',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post('/logs/backup', {});

      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const logsOperations = {
  fetchLogsCombined,
  fetchLogsError,
  postLogDownload,
  backupLogsPost,
};

export default logsOperations;
