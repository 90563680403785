import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const registerExpert = createAsyncThunk(
  'experts/register',
  async (data, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post('/experts/register', data);

      toasty.toastSuccess('Created successfully');
      return response.data;
    } catch (error) {
      if (error.response.status === 409) {
        return thunkApi.rejectWithValue(
          'Therapist with the same email already exists'
        );
      }
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        return thunkApi.rejectWithValue('Please try again later.');
      }
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const addExpertPassword = createAsyncThunk(
  'experts/password',
  async (data, thunkApi) => {
    try {
      const response = await instance.patch('/experts/password', data);

      toasty.toastSuccess('Created successfully');
      return response.data;
    } catch (error) {
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        return thunkApi.rejectWithValue(
          'The server is currently unable. Please try again later.'
        );
      }
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchAllExperts = createAsyncThunk(
  'experts/fetch',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.get('/experts/all');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchAllExpertsByQuery = createAsyncThunk(
  'expert/fetch/filter',
  async (params, thunkApi) => {
    const {
      page,
      limit,
      email,
      category,
      experience,
      // name,
      gender,
      genderfriendly,
      firstname,
      lastname,
      price,
      rate,
      specialization,
      status,
      verify,
      banned,
      attachments,
      avatar,
      education,
      sort,
    } = params;
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/experts/all', {
        params: {
          page,
          limit,
          email,
          gender,
          genderfriendly,
          firstname,
          lastname,
          category,
          experience,
          price: price ?? '',
          rate,
          specialization,
          status,
          verify,
          banned,
          attachments,
          avatar,
          education,
          sort,
        },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchOneExpert = createAsyncThunk(
  'oneExpert/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/experts/id/${_id}`);
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const inviteExpert = createAsyncThunk(
  'experts/invite',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post(`/experts/invite/${_id}`, {});

      toasty.toastSuccess('Invitation sent successfully');
      return response.data;
    } catch (error) {
      if (error.response.status === 409) {
        return thunkApi.rejectWithValue(
          'Therapist with the same email already exists'
        );
      }
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        return thunkApi.rejectWithValue('Please try again later.');
      }

      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const updateAvatar = createAsyncThunk(
  'updateAvatar/post',
  async (formData, thunkApi) => {
    console.log(formData);
    const state = thunkApi.getState();
    const token = state.auth.token;
    const { _id } = state.experts.oneExpert.expert;

    setToken(token);

    try {
      const response = await instance.post(`/experts/avatar/${_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toasty.toastSuccess('Avatar updated successfully');
      return response.data;
    } catch (error) {
      toasty.toastError('Please try again later.');
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const updateStatus = createAsyncThunk(
  'updateStatus/patch',
  async (status, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;
    const { _id } = state.experts.oneExpert.expert;

    setToken(token);
    try {
      const response = await instance.patch(`/experts/status/${_id}`, {
        status,
      });
      return response.data;
    } catch (error) {
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        return thunkApi.rejectWithValue('Please try again later.');
      }
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const uploadDocuments = createAsyncThunk(
  'uploadDocuments/post',
  async (formData, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;
    const { _id } = state.experts.oneExpert.expert;

    setToken(token);
    try {
      const response = await instance.post(
        `/experts/documents/${_id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      toasty.toastSuccess('Documents updated successfully');
      return response.data;
    } catch (error) {
      toasty.toastError('Please try again later.');
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const getExpertDocuments = createAsyncThunk(
  'getExpertDocuments/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/experts/documents/${_id}`);
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const deleteDocuments = createAsyncThunk(
  'deleteDocuments/delete',
  async ({ _id, attachmentIds }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.delete(`/experts/documents/${_id}`, {
        data: { attachmentIds },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const updateDocument = createAsyncThunk(
  'updateDocument/patch',
  async ({ _id, attachments }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.patch(`/experts/documents/${_id}`, {
        attachments,
      });

      toasty.toastSuccess('Renamed successfully');
      return response.data;
    } catch (error) {
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        return thunkApi.rejectWithValue('Please try again later.');
      }

      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const updateTherapistInfo = createAsyncThunk(
  'updateTherapistInfo/patch',
  async (data, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;
    const { _id } = state.experts.oneExpert.expert;

    setToken(token);
    try {
      const response = await instance.patch(`/experts/id/${_id}`, data);
      return response.data;
    } catch (error) {
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        return thunkApi.rejectWithValue('Please try again later.');
      }

      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const banExpert = createAsyncThunk(
  'banExpert/patch',
  async ({ _id, reason, cancelConsultations }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/experts/ban/${_id}`, {
        reason,
        cancelConsultations,
      });
      toasty.toastSuccess('Blocked successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const unbanExpert = createAsyncThunk(
  'unbanExpert/patch',
  async ({ _id, reason }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/experts/unban/${_id}`, {
        reason,
      });

      toasty.toastSuccess('Unblocked successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const expertsOperations = {
  fetchAllExperts,
  fetchAllExpertsByQuery,
  fetchOneExpert,
  registerExpert,
  addExpertPassword,
  inviteExpert,
  updateAvatar,
  updateStatus,
  uploadDocuments,
  getExpertDocuments,
  updateDocument,
  deleteDocuments,
  updateTherapistInfo,
  banExpert,
  unbanExpert,
};

export default expertsOperations;
