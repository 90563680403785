import { createSlice } from '@reduxjs/toolkit';
import complaintsOperations from './complaints-operations';
import errorCodes from '../../shared/errorCodes/errorCodes';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  const errorCode = action.payload;
  state.error = errorCodes[errorCode] || errorCode;
};

const complaintsSlice = createSlice({
  name: 'complaints',
  initialState: {
    complaintsList: [],
    oneComplaint: {},
    therapistComplaints: { complaints: [], totalComplaints: null },
    userComplaints: { complaints: [], totalComplaints: null },
    isLoading: false,
    error: null,
    total: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    complaintsClearError: state => {
      state.error = null;
    },
    resetComplaints: state => {
      state.therapistComplaints = { complaints: [], totalComplaints: null };
    },
  },

  extraReducers: builder => {
    builder
      .addCase(
        complaintsOperations.fetchAllComplaintsByQuery.pending,
        handlePending
      )
      .addCase(
        complaintsOperations.fetchAllComplaintsByQuery.fulfilled,
        (state, action) => {
          state.complaintsList = action.payload.complaints;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        complaintsOperations.fetchAllComplaintsByQuery.rejected,
        handleRejected
      )

      .addCase(
        complaintsOperations.fetchTherapistComplaints.pending,
        handlePending
      )
      .addCase(
        complaintsOperations.fetchTherapistComplaints.fulfilled,
        (state, action) => {
          state.therapistComplaints.complaints = action.payload.complaints;
          state.therapistComplaints.totalComplaints = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        complaintsOperations.fetchTherapistComplaints.rejected,
        handleRejected
      )

      .addCase(
        complaintsOperations.fetchCustomerComplaints.pending,
        handlePending
      )
      .addCase(
        complaintsOperations.fetchCustomerComplaints.fulfilled,
        (state, action) => {
          state.userComplaints.complaints = action.payload.complaints;
          state.userComplaints.totalComplaints = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        complaintsOperations.fetchCustomerComplaints.rejected,
        handleRejected
      );

    //   .addCase(expertsOperations.fetchOneExpert.pending, handlePending)
    //   .addCase(expertsOperations.fetchOneExpert.fulfilled, (state, action) => {
    //     state.oneExpert = action.payload;
    //     state.isLoading = false;
    //     state.error = false;
    //   })
    //   .addCase(expertsOperations.fetchOneExpert.rejected, handleRejected)

    //   .addCase(expertsOperations.registerExpert.pending, handlePending)
    //   .addCase(expertsOperations.registerExpert.fulfilled, (state, action) => {
    //     if (!state.oneExpert) {
    //       state.oneExpert = {};
    //     }
    //     if (!state.oneExpert.expert) {
    //       state.oneExpert.expert = {};
    //     }
    //     state.oneExpert.expert._id = action.payload.expert._id;
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.registerExpert.rejected, handleRejected)

    //   .addCase(expertsOperations.addExpertPassword.pending, handlePending)
    //   .addCase(expertsOperations.addExpertPassword.fulfilled, state => {
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.addExpertPassword.rejected, handleRejected)

    //   .addCase(expertsOperations.inviteExpert.pending, handlePending)
    //   .addCase(expertsOperations.inviteExpert.fulfilled, state => {
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.inviteExpert.rejected, handleRejected)

    //   .addCase(expertsOperations.fetchAllExpertsByQuery.pending, handlePending)
    //   .addCase(
    //     expertsOperations.fetchAllExpertsByQuery.fulfilled,
    //     (state, action) => {
    //       state.expertsList = action.payload.experts;
    //       state.total = action.payload.total;
    //       state.isLoading = false;
    //       state.error = false;
    //     }
    //   )
    //   .addCase(
    //     expertsOperations.fetchAllExpertsByQuery.rejected,
    //     handleRejected
    //   )

    //   .addCase(expertsOperations.updateAvatar.pending, handlePending)
    //   .addCase(expertsOperations.updateAvatar.fulfilled, (state, action) => {
    //     state.oneExpert.expert.avatar = action.payload.expert.avatar;
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.updateAvatar.rejected, handleRejected)

    //   .addCase(expertsOperations.updateStatus.pending, handlePending)
    //   .addCase(expertsOperations.updateStatus.fulfilled, state => {
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.updateStatus.rejected, handleRejected)

    //   .addCase(expertsOperations.uploadDocuments.pending, handlePending)
    //   .addCase(expertsOperations.uploadDocuments.fulfilled, (state, action) => {
    //     console.log(action.payload);

    //     state.oneExpert.expert.attachments = action.payload.attachments;

    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.uploadDocuments.rejected, handleRejected)

    //   .addCase(expertsOperations.getExpertDocuments.pending, handlePending)
    //   .addCase(
    //     expertsOperations.getExpertDocuments.fulfilled,
    //     (state, action) => {
    //       // console.log(action.payload, 'get documents');
    //       state.oneExpert.expert.attachments = action.payload.attachments;
    //       state.isLoading = false;
    //       state.error = false;
    //     }
    //   )
    //   .addCase(expertsOperations.getExpertDocuments.rejected, handleRejected)

    //   .addCase(expertsOperations.updateDocument.pending, handlePending)
    //   .addCase(expertsOperations.updateDocument.fulfilled, (state, action) => {
    //     state.oneExpert.expert.attachments = action.payload.attachments;
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.updateDocument.rejected, handleRejected)

    //   .addCase(expertsOperations.deleteDocuments.pending, handlePending)
    //   .addCase(expertsOperations.deleteDocuments.fulfilled, (state, action) => {
    //     console.log(action.payload, 'deleteDocuments');
    //     state.oneExpert.expert.attachments = action.payload.attachments;
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.deleteDocuments.rejected, handleRejected)

    //   .addCase(expertsOperations.updateTherapistInfo.pending, handlePending)
    //   .addCase(
    //     expertsOperations.updateTherapistInfo.fulfilled,
    //     (state, action) => {
    //       state.oneExpert.expert = action.payload.expert;
    //       state.isLoading = false;
    //       state.error = null;
    //     }
    //   )
    //   .addCase(expertsOperations.updateTherapistInfo.rejected, handleRejected)

    //   .addCase(expertsOperations.banExpert.pending, handlePending)
    //   .addCase(expertsOperations.banExpert.fulfilled, (state, action) => {
    //     state.oneExpert.expert = action.payload.expert;
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.banExpert.rejected, handleRejected)

    //   .addCase(expertsOperations.unbanExpert.pending, handlePending)
    //   .addCase(expertsOperations.unbanExpert.fulfilled, (state, action) => {
    //     state.oneExpert.expert = action.payload.expert;
    //     state.isLoading = false;
    //     state.error = null;
    //   })
    //   .addCase(expertsOperations.unbanExpert.rejected, handleRejected);
  },
});

export const { setError, complaintsClearError, resetComplaints } =
  complaintsSlice.actions;

export default complaintsSlice.reducer;
