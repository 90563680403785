import { createAsyncThunk } from '@reduxjs/toolkit';
import * as toasty from '../../shared/toastify/toastify';

import * as api from '../../shared/services/app/auth';

export const register = createAsyncThunk(
  'auth/register',
  async (data, { rejectWithValue }) => {
    try {
      const result = await api.registerUser(data);
      toasty.toastSuccess('Request resent successfully');
      return result;
    } catch (error) {
      if (error.response.status === 409) {
        return rejectWithValue('BO User with the same email already exists');
      }

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const resendInvite = createAsyncThunk(
  'auth/resendInvite',
  async (_id, { rejectWithValue }) => {
    try {
      const result = await api.getResendInvite(_id);
      toasty.toastSuccess('Request resent successfully');
      return {
        data: result.data,
        status: result.status,
        statusText: result.statusText,
      };
    } catch (error) {
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        return rejectWithValue(
          'The server is currently unable. Please try again later.'
        );
      }
      toasty.toastError('Please try again later.');
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPassword = createAsyncThunk(
  'auth/password',
  async (data, { rejectWithValue }) => {
    try {
      const result = await api.updateUserPassword(data);
      return result;
    } catch (error) {
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        return rejectWithValue(
          'The server is currently unable. Please try again later.'
        );
      }
      return rejectWithValue(error.response.statusText);
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const result = await api.loginUser(data);
      return result;
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 400) {
        return rejectWithValue(
          'Your login or password is incorrect or your account is blocked. Please input correct credentials or contact your manager'
        );
      }
      if (error && error.response && error.response.status === 505) {
        return rejectWithValue(
          'The server is currently unable. Please try again later.'
        );
      }
      return rejectWithValue('Please try again');
    }
  }
);

export const current = createAsyncThunk(
  'auth/current',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState();
      const data = await api.getCurrentUser(auth.token);
      if (!auth.token) {
        return rejectWithValue('Unable to fetch user');
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { auth } = getState();
      if (!auth.token) {
        return false;
      }
    },
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const data = await api.logoutUser();
      return data;
    } catch ({ response }) {
      if (response && response.status !== 401) {
        return rejectWithValue('Please try again');
      }
      return;
    }
  }
);
