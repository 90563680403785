const errorCodes = {
  ACCOUNT_CREATED: 'New account created',
  ACCOUNT_UPDATED: 'Existed account personal info updated',
  PASSWORD_UPDATED: 'Password reset',
  CONSULTATION_CREATED: 'New consultation created',
  CONSULTAION_APPROVED: 'Consultation approved by expert',
  CONSULTAION_REJECTED: 'Consultation rejected by expert with no reason',
  CONSULTAION_REJECTED_DATE:
    'Consultation rejected by expert due to date or time reason',
  CONSULTAION_REJECTED_SPECIALIZATION:
    'Consultation rejected by expert due to specialization reason',
  CONSULTAION_CANCELLED_USER: 'Consultation cancelled by user',
  CONSULTAION_CANCELLED_EXPERT: 'Consultation cancelled by expert',
  CONSULTAION_CANCELLED_ADMIN: 'Consultation cancelled by admin',
  CONSULTAION_FAILED_USER:
    "Consultation failed, user wasn't present at the consultation",
  CONSULTAION_FAILED_EXPERT:
    "Consultation failed, expert wasn't present at the consultation",
  CONSULTAION_FAILED_TECHNICAL: 'Consultation failed due to technical problems',
  CONSULTAION_FINISH: 'Consultation finished, please add a review',
  RESCHEDULE: 'User request for consultation rescheduling',
  RESCHEDULE_APPROVED: 'Request for consultation rescheduling - Approved',
  RESCHEDULE_REJECTED: 'Request for consultation rescheduling - Rejected',
  PAYMENT_ADDED: 'User added a new payment method',
  PAYMENT_REMOVED: 'User deleted a payment method',
  REVIEW_ADDED: 'User added a new review to expert',
  REVIEW_APPROVED: 'Admin approved the review',
  REVIEW_REJECTED: 'Admin rejected the review',
  //   Expert
  SCHEDULE_NOT_FOUND: 'Therapist has not added a schedule',
  EXPERT_MISSING_PASSWORD: 'Therapist did not set a password',
  INVALID_ID: 'Invalid id',
};

export default errorCodes;
