import { createSelector } from 'reselect';

const getAuthData = state => state.auth;

export const getAuth = createSelector([getAuthData], auth => ({
  isLogin: auth.isLogin,
  token: auth.token,
}));

export const isUserLogin = state => state.auth.isLogin;

export const isLoading = state => state.auth.isLoading;

export const getUser = state => state.auth.user;
