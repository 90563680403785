import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchAllUsers = createAsyncThunk('users/fetch', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const token = state.auth.token;

  setToken(token);

  try {
    const response = await instance.get('/users/all');
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.message);
  }
});

const fetchAllUsersByQuery = createAsyncThunk(
  'users/fetch/filter',
  async (params, thunkApi) => {
    const { page, limit, email, name, gender, verify, rating } = params;

    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.get('/users/all', {
        params: {
          page,
          limit,
          email,
          gender,
          name,
          rating,
          verify,
        },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchOneUser = createAsyncThunk(
  'oneUser/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/users/${_id}`);
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const banUser = createAsyncThunk(
  'banUser/patch',
  async ({ _id, reason, cancelConsultations }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.patch(`/users/ban/${_id}`, {
        reason,
        cancelConsultations,
      });
      toasty.toastSuccess('Blocked successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const unbanUser = createAsyncThunk(
  'unbanUser/patch',
  async ({ _id, reason }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.patch(`/users/unban/${_id}`, { reason });
      toasty.toastSuccess('Unblocked successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const usersOperations = {
  fetchAllUsers,
  fetchAllUsersByQuery,
  fetchOneUser,
  banUser,
  unbanUser,
};

export default usersOperations;
