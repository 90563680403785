import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchAllReviews = createAsyncThunk(
  'reviews/fetch',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.get('/reviews');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchAllReviewsByQuery = createAsyncThunk(
  'reviews/fetch/filter',
  async (params, thunkApi) => {
    const { page, limit, status, userName, expertName, rating, recommend } =
      params;

    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.get('/reviews', {
        params: {
          page,
          limit,
          status,
          userName,
          expertName,
          rating,
          recommend,
        },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
const fetchOneReview = createAsyncThunk(
  'oneReview/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/reviews/${_id}`);
      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const approvedReviewStatus = createAsyncThunk(
  'approvedReviewStatus',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/reviews/${_id}`, {
        status: 'approved',
      });

      toasty.toastSuccess('Status change successfully');
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const rejectedReviewStatus = createAsyncThunk(
  'rejectedReviewStatus',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.patch(`/reviews/${_id}`, {
        status: 'rejected',
      });

      toasty.toastSuccess('Status change successfully');
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchUserReviews = createAsyncThunk(
  'userReviews/fetch',
  async ({ _id, page, limit }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/users/reviews/${_id}`, {
        params: {
          page,
          limit,
        },
      });
      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const updateReviewStatus = createAsyncThunk(
  'updateUserReviewStatus/patch',
  async ({ _id, data }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/admin/reviews/${_id}`, data);

      toasty.toastSuccess('Status change successfully');
      return response.data;
    } catch (error) {
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        toasty.toastError('Please try again later');
        return thunkApi.rejectWithValue('Please try again later');
      }
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchTherapistReviews = createAsyncThunk(
  'therapistReviews/fetch',
  async ({ _id, page, limit }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/experts/reviews/${_id}`, {
        params: {
          page,
          limit,
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const reviewsOperations = {
  fetchAllReviews,
  fetchAllReviewsByQuery,
  fetchOneReview,
  approvedReviewStatus,
  rejectedReviewStatus,
  fetchUserReviews,
  updateReviewStatus,
  fetchTherapistReviews,
};

export default reviewsOperations;
