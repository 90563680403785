import { createSlice } from '@reduxjs/toolkit';
import consultationsOperations from './consultations-operations';

import errorCodes from '../../shared/errorCodes/errorCodes';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  const errorCode = action.payload;
  state.error = errorCodes[errorCode] || errorCode;
};

const consultationsSlice = createSlice({
  name: 'consultations',
  initialState: {
    consultationsList: [],
    archiveConsultations: {},
    userConsultations: {},
    expertConsultations: {},
    oneConsultation: {},
    schedule: {},
    isLoading: false,
    error: null,
    total: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    consultationsClearError: state => {
      state.error = null;
    },
    resetConsultations: state => {
      state.schedule = {};
      state.expertConsultations = {};
    },
  },

  extraReducers: builder => {
    builder
      .addCase(
        consultationsOperations.fetchAllConsultations.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.fetchAllConsultations.fulfilled,
        (state, action) => {
          state.consultationsList = action.payload.consultations;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(
        consultationsOperations.fetchAllConsultations.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.fetchAllConsultationsByQuery.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.fetchAllConsultationsByQuery.fulfilled,
        (state, action) => {
          state.consultationsList = action.payload.consultations;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(
        consultationsOperations.fetchAllConsultationsByQuery.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.fetchAllArchiveConsultationsByQuery.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.fetchAllArchiveConsultationsByQuery.fulfilled,
        (state, action) => {
          state.archiveConsultations.consultations =
            action.payload.consultations;
          state.archiveConsultations.total = action.payload.total;
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(
        consultationsOperations.fetchAllArchiveConsultationsByQuery.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.fetchUserConsultations.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.fetchUserConsultations.fulfilled,
        (state, action) => {
          state.userConsultations.consultations = action.payload.consultations;
          state.userConsultations.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.fetchUserConsultations.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.fetchUserArchiveConsultations.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.fetchUserArchiveConsultations.fulfilled,
        (state, action) => {
          state.userConsultations.archiveConsultations =
            action.payload.consultations;
          state.userConsultations.totalList = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.fetchUserArchiveConsultations.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.fetchTherapistConsultations.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.fetchTherapistConsultations.fulfilled,
        (state, action) => {
          state.expertConsultations.consultations =
            action.payload.consultations;
          state.expertConsultations.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.fetchTherapistConsultations.rejected,
        handleRejected
      )
      .addCase(
        consultationsOperations.fetchTherapistArchiveConsultations.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.fetchTherapistArchiveConsultations.fulfilled,
        (state, action) => {
          state.expertConsultations.archiveConsultations =
            action.payload.consultations;
          state.expertConsultations.total = action.payload.total;
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(
        consultationsOperations.fetchTherapistArchiveConsultations.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.fetchOneConsultation.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.fetchOneConsultation.fulfilled,
        (state, action) => {
          state.oneConsultation = action.payload.consultation;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.fetchOneConsultation.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.rescheduleConsultations.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.rescheduleConsultations.fulfilled,
        (state, action) => {
          state.oneConsultation = action.payload;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.rescheduleConsultations.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.declineConsultation.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.declineConsultation.fulfilled,
        (state, action) => {
          state.oneConsultation = action.payload;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.declineConsultation.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.finishConsultation.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.finishConsultation.fulfilled,
        (state, action) => {
          state.oneConsultation = action.payload;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.finishConsultation.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.cancelConsultation.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.cancelConsultation.fulfilled,
        (state, action) => {
          const index = state.consultationsList.findIndex(
            item => item._id === action.payload.consultation._id
          );
          state.consultationsList.splice(index, 1, action.payload.consultation);
          state.oneConsultation = action.payload.consultation;
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(
        consultationsOperations.cancelConsultation.rejected,
        handleRejected
      )
      .addCase(
        consultationsOperations.approveConsultation.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.approveConsultation.fulfilled,
        (state, action) => {
          state.oneConsultation = action.payload;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.approveConsultation.rejected,
        handleRejected
      )

      .addCase(consultationsOperations.fetchSchedule.pending, handlePending)
      .addCase(
        consultationsOperations.fetchSchedule.fulfilled,
        (state, action) => {
          state.schedule.schedule = action.payload.schedule;
          state.schedule.defaultSchedule = action.payload.defaultSchedule;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(consultationsOperations.fetchSchedule.rejected, handleRejected)

      .addCase(consultationsOperations.addExpertSchedule.pending, handlePending)
      .addCase(
        consultationsOperations.addExpertSchedule.fulfilled,
        (state, action) => {
          // console.log(action.payload.schedule, 'action.payload');
          state.schedule.schedule = action.payload.schedule;
          // state.schedule.defaultSchedule = action.payload.defaultSchedule;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.addExpertSchedule.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.addDefaultSchedule.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.addDefaultSchedule.fulfilled,
        (state, action) => {
          state.schedule = action.payload;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.addDefaultSchedule.rejected,
        handleRejected
      )

      .addCase(
        consultationsOperations.updateDefaultSchedule.pending,
        handlePending
      )
      .addCase(
        consultationsOperations.updateDefaultSchedule.fulfilled,
        (state, action) => {
          state.schedule.defaultSchedule = action.payload;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        consultationsOperations.updateDefaultSchedule.rejected,
        handleRejected
      );
  },
});

export const { setError, consultationsClearError, resetConsultations } =
  consultationsSlice.actions;

export default consultationsSlice.reducer;
