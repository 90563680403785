import { createSlice } from '@reduxjs/toolkit';
import requestsOperations from './requests-operations';
import errorCodes from '../../shared/errorCodes/errorCodes';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  const errorCode = action.payload;
  state.error = errorCodes[errorCode] || errorCode;
};

const requestsSlice = createSlice({
  name: 'requests',
  initialState: {
    requestsList: [],
    oneRequest: {},
    total: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    requestsClearError: state => {
      state.error = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(requestsOperations.fetchAllRequests.pending, handlePending)
      .addCase(
        requestsOperations.fetchAllRequests.fulfilled,
        (state, action) => {
          state.requestsList = action.payload.data;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(requestsOperations.fetchAllRequests.rejected, handleRejected)

      .addCase(
        requestsOperations.fetchAllRequestsByQuery.pending,
        handlePending
      )
      .addCase(
        requestsOperations.fetchAllRequestsByQuery.fulfilled,
        (state, action) => {
          state.requestsList = action.payload.data;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        requestsOperations.fetchAllRequestsByQuery.rejected,
        handleRejected
      )

      .addCase(requestsOperations.fetchOneRequest.pending, handlePending)
      .addCase(
        requestsOperations.fetchOneRequest.fulfilled,
        (state, action) => {
          state.oneRequest = action.payload.data;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(requestsOperations.fetchOneRequest.rejected, handleRejected)

      .addCase(requestsOperations.deleteRequest.pending, handlePending)
      .addCase(requestsOperations.deleteRequest.fulfilled, (state, action) => {
        console.log(action);

        const index = state.requestsList.findIndex(
          item => item._id === action.meta.arg
        );
        state.requestsList.splice(index, 1);
        state.isLoading = false;
        state.error = false;
      })
      .addCase(requestsOperations.deleteRequest.rejected, handleRejected)

      .addCase(requestsOperations.readRequest.pending, handlePending)
      .addCase(requestsOperations.readRequest.fulfilled, (state, action) => {
        state.oneRequest = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(requestsOperations.readRequest.rejected, handleRejected);
  },
});

export const { setError, partnersClearError } = requestsSlice.actions;

export default requestsSlice.reducer;
