import io from 'socket.io-client';

const BASE_URL = 'https://api.anomi.app/';

export const connectToChat = (token, onMessage, onReadMessage) => {
  const socket = io(BASE_URL, {
    transports: ['websocket'],
    auth: {
      token: token,
    },
    reconnection: true,
  });

  socket.on('connect', () => {
    console.log('Socket connected');
    socket.emit('subscribeToMessages');
  });

  socket.on('connect_error', error => {
    console.error('Connection error:', error);
  });

  socket.on('disconnect', reason => {
    console.log('disconnect:', reason);
  });

  socket.on('newMessage', msg => {
    // console.log('newMessage', msg);
    if (onMessage) {
      onMessage(msg);
    }
  });

  socket.on('readMessages', msg => {
    console.log('readMessages:', msg);
    if (onReadMessage) {
      onReadMessage(msg);
    }
  });

  return socket;
};
