import { createSlice } from '@reduxjs/toolkit';
import adminUsersOperations from './admin-users-operations';


const handlePending = state => {
  state.isLoading = true;
};

const handleFulfilledBanAndUnban = (state, action) => {
  const index = state.adminUsersList.findIndex(
    item => item._id === action.payload._id
  );
  state.adminUsersList.splice(index, 1, action.payload);
  state.isLoading = false;
  state.error = false;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};


const adminUsersSlice = createSlice({
  name: 'adminUsers',
  initialState: {
    adminUsersList: [],
    oneAdminUser:{},
    isLoading: false,
    error: null,
    total: null,
  },
   
  extraReducers: builder => {
    builder
      .addCase(adminUsersOperations.fetchAllAdminUsers.pending, handlePending)
      .addCase(
        adminUsersOperations.fetchAllAdminUsers.fulfilled,
        (state, action) => {
          state.adminUsersList = action.payload.admins;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(adminUsersOperations.fetchAllAdminUsers.rejected, handleRejected)
      .addCase(adminUsersOperations.fetchOneAdminUser.pending, handlePending)
      .addCase(
        adminUsersOperations.fetchOneAdminUser.fulfilled,
        (state, action) => {
          state.oneAdminUser = action.payload;
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(adminUsersOperations.fetchOneAdminUser.rejected, handleRejected)
      .addCase(adminUsersOperations.banAdminUser.pending, handlePending)
      .addCase(adminUsersOperations.banAdminUser.fulfilled, handleFulfilledBanAndUnban)
      .addCase(adminUsersOperations.banAdminUser.rejected, handleRejected)
      .addCase(adminUsersOperations.unbanAdminUser.pending, handlePending)
      .addCase(adminUsersOperations.unbanAdminUser.fulfilled, handleFulfilledBanAndUnban)
      .addCase(adminUsersOperations.unbanAdminUser.rejected, handleRejected)
      .addCase(adminUsersOperations.fetchAllAdminUsersByQuery.pending, handlePending)
      .addCase(adminUsersOperations.fetchAllAdminUsersByQuery.fulfilled, (state, action) => {
        state.adminUsersList = action.payload.admins;
        state.total = action.payload.total;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(adminUsersOperations.fetchAllAdminUsersByQuery.rejected, handleRejected);
  },
});

export default adminUsersSlice.reducer;
