import { createSlice } from '@reduxjs/toolkit';
import reviewsOperations from '../reviews/reviews-operations';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const handleChangeStatus = (state, action) => {
  const index = state.reviewsList.findIndex(
    item => item._id === action.payload.review._id
  );
  state.reviewsList.splice(index, 1, action.payload.review);
  state.isLoading = false;
  state.error = false;
};

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {
    reviewsList: [],
    userReviews: {},
    therapistReviews: { reviews: [], totalReviews: null },
    oneReview: {},
    isLoading: false,
    error: null,
    total: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    reviewsClearError: state => {
      state.error = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(reviewsOperations.fetchAllReviews.pending, handlePending)
      .addCase(reviewsOperations.fetchAllReviews.fulfilled, (state, action) => {
        state.reviewsList = action.payload.reviews;
        state.total = action.payload.total;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(reviewsOperations.fetchAllReviews.rejected, handleRejected)

      .addCase(reviewsOperations.fetchOneReview.pending, handlePending)
      .addCase(reviewsOperations.fetchOneReview.fulfilled, (state, action) => {
        state.oneReview = action.payload.review;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(reviewsOperations.fetchOneReview.rejected, handleRejected)

      .addCase(reviewsOperations.fetchAllReviewsByQuery.pending, handlePending)
      .addCase(
        reviewsOperations.fetchAllReviewsByQuery.fulfilled,
        (state, action) => {
          state.reviewsList = action.payload.reviews;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(
        reviewsOperations.fetchAllReviewsByQuery.rejected,
        handleRejected
      )
      .addCase(reviewsOperations.approvedReviewStatus.pending, handlePending)
      .addCase(
        reviewsOperations.approvedReviewStatus.fulfilled,
        handleChangeStatus
      )
      .addCase(reviewsOperations.approvedReviewStatus.rejected, handleRejected)
      .addCase(reviewsOperations.rejectedReviewStatus.pending, handlePending)
      .addCase(
        reviewsOperations.rejectedReviewStatus.fulfilled,
        handleChangeStatus
      )
      .addCase(reviewsOperations.rejectedReviewStatus.rejected, handleRejected)

      .addCase(reviewsOperations.fetchUserReviews.pending, handlePending)
      .addCase(
        reviewsOperations.fetchUserReviews.fulfilled,
        (state, action) => {
          state.userReviews = {
            reviews: action.payload.reviews,
            totalReviews: action.payload.total,
          };
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(reviewsOperations.fetchUserReviews.rejected, handleRejected)

      .addCase(reviewsOperations.updateReviewStatus.pending, handlePending)
      .addCase(
        reviewsOperations.updateReviewStatus.fulfilled,
        (state, action) => {
          state.oneReview = action.payload;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(reviewsOperations.updateReviewStatus.rejected, handleRejected)

      .addCase(reviewsOperations.fetchTherapistReviews.pending, handlePending)
      .addCase(
        reviewsOperations.fetchTherapistReviews.fulfilled,
        (state, action) => {
          state.therapistReviews = {
            reviews: action.payload.reviews,
            totalReviews: action.payload.total,
          };
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        reviewsOperations.fetchTherapistReviews.rejected,
        handleRejected
      );
  },
});

export const { setError, reviewsClearError } = reviewsSlice.actions;

export default reviewsSlice.reducer;
