import { createSlice } from '@reduxjs/toolkit';
import logsOperations from './logs-operations';
import errorCodes from '../../shared/errorCodes/errorCodes';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  const errorCode = action.payload;
  state.error = errorCodes[errorCode] || errorCode;
};

const logsSlice = createSlice({
  name: 'logs',
  initialState: {
    logsList: [],
    oneLog: {},
    isLoading: false,
    error: null,
    total: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    logsClearError: state => {
      state.error = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(logsOperations.fetchLogsCombined.pending, handlePending)
      .addCase(logsOperations.fetchLogsCombined.fulfilled, (state, action) => {
        state.logsList = action.payload;
        state.total = action.payload.length;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(logsOperations.fetchLogsCombined.rejected, handleRejected)

      .addCase(logsOperations.fetchLogsError.pending, handlePending)
      .addCase(logsOperations.fetchLogsError.fulfilled, (state, action) => {
        state.logsList = action.payload;
        state.total = action.payload.length;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(logsOperations.fetchLogsError.rejected, handleRejected)

      .addCase(logsOperations.postLogDownload.pending, handlePending)
      .addCase(logsOperations.postLogDownload.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(logsOperations.postLogDownload.rejected, handleRejected)

      .addCase(logsOperations.backupLogsPost.pending, handlePending)
      .addCase(logsOperations.backupLogsPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(logsOperations.backupLogsPost.rejected, handleRejected);
  },
});

export const { setError, logsClearError } = logsSlice.actions;

export default logsSlice.reducer;
