import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchAllAdminUsers = createAsyncThunk(
  'admin/fetch',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/all');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const fetchAllAdminUsersByQuery = createAsyncThunk(
  'admin/fetch/filter',
  async (q, thunkApi) => {
    const { page, limit, name, email, role, status } = q;
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/all', {
        params: { page, limit, name, email, role, status },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const fetchOneAdminUser = createAsyncThunk(
  'oneAdmin/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/id/${_id}`);
      return response.data;
    } catch (error) {
      toasty.toastError('Please try again later');
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const banAdminUser = createAsyncThunk(
  'banAdmin/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/ban/${_id}`, {});
      toasty.toastSuccess('Status change successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const unbanAdminUser = createAsyncThunk(
  'unbanAdmin/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/unban/${_id}`, {});
      toasty.toastSuccess('Status change successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const adminUsersOperations = {
  fetchAllAdminUsers,
  fetchAllAdminUsersByQuery,
  fetchOneAdminUser,
  banAdminUser,
  unbanAdminUser,
};

export default adminUsersOperations;
