import { logout } from '../../redux/auth/auth-operations';

const unauthorizedMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    if (
      (action.type.endsWith('/rejected') && action.payload?.status === 401) ||
      action.payload === 'UNAUTHORIZED' ||
      action.payload === 'TOKEN_EXPIRED'
    ) {
      dispatch(logout());
    }
    return next(action);
  };

export default unauthorizedMiddleware;
