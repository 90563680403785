import { RotatingLines } from 'react-loader-spinner';

import { Container } from './Loader.styled';

const Loader = () => {
  return (
    <Container>
      <RotatingLines strokeColor="var(--gray-color)" />
    </Container>
  );
};

export default Loader;
