import { createSlice } from '@reduxjs/toolkit';
import partnersOperations from './partners-operations';
import errorCodes from '../../shared/errorCodes/errorCodes';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  const errorCode = action.payload;
  state.error = errorCodes[errorCode] || errorCode;
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState: {
    partnersList: [],
    onePartner: {},
    total: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    partnersClearError: state => {
      state.error = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(partnersOperations.fetchAllPartners.pending, handlePending)
      .addCase(
        partnersOperations.fetchAllPartners.fulfilled,
        (state, action) => {
          state.partnersList = action.payload.partners;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(partnersOperations.fetchAllPartners.rejected, handleRejected)

      .addCase(
        partnersOperations.fetchAllPartnersByQuery.pending,
        handlePending
      )
      .addCase(
        partnersOperations.fetchAllPartnersByQuery.fulfilled,
        (state, action) => {
          state.partnersList = action.payload.partners;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(
        partnersOperations.fetchAllPartnersByQuery.rejected,
        handleRejected
      )

      .addCase(partnersOperations.fetchOnePartner.pending, handlePending)
      .addCase(
        partnersOperations.fetchOnePartner.fulfilled,
        (state, action) => {
          state.onePartner = action.payload.partner;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(partnersOperations.fetchOnePartner.rejected, handleRejected)

      .addCase(partnersOperations.addNewPartner.pending, handlePending)
      .addCase(partnersOperations.addNewPartner.fulfilled, (state, action) => {
        state.partnersList.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(partnersOperations.addNewPartner.rejected, handleRejected)

      .addCase(partnersOperations.deletePartner.pending, handlePending)
      .addCase(partnersOperations.deletePartner.fulfilled, (state, action) => {
        const index = state.partnersList.findIndex(
          item => item._id === action.meta.arg
        );
        state.partnersList.splice(index, 1);
        state.isLoading = false;
        state.error = false;
      })
      .addCase(partnersOperations.deletePartner.rejected, handleRejected)

      .addCase(partnersOperations.connectExpert.pending, handlePending)
      .addCase(partnersOperations.connectExpert.fulfilled, (state, action) => {
        // state.partnersList;
        state.onePartner = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(partnersOperations.connectExpert.rejected, handleRejected)

      .addCase(partnersOperations.updatePartner.pending, handlePending)
      .addCase(partnersOperations.updatePartner.fulfilled, (state, action) => {
        const index = state.partnersList.findIndex(
          item => item._id === action.payload._id
        );
        state.onePartner = action.payload;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(
        partnersOperations.deleteExpertFromPartner.pending,
        handlePending
      )
      .addCase(
        partnersOperations.deleteExpertFromPartner.fulfilled,
        (state, action) => {
          const index = state.onePartner?.experts.findIndex(
            item => item._id === action.meta.arg
          );

          state.onePartner?.experts.splice(index, 1);
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(
        partnersOperations.deleteExpertFromPartner.rejected,
        handleRejected
      );
  },
});

export const { setError, partnersClearError } = partnersSlice.actions;

export default partnersSlice.reducer;
