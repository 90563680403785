import { createSlice } from '@reduxjs/toolkit';
import usersOperations from './users-operations';

import errorCodes from '../../shared/errorCodes/errorCodes';

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, action) => {
  state.isLoading = false;
  const errorCode = action.payload;
  state.error = errorCodes[errorCode] || errorCode;
};

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    usersList: [],
    oneUser: {},
    isLoading: false,
    error: null,
    total: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    userClearError: state => {
      state.error = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(usersOperations.fetchAllUsers.pending, handlePending)
      .addCase(usersOperations.fetchAllUsers.fulfilled, (state, action) => {
        state.expertsList = action.payload.users;
        state.total = action.payload.total;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(usersOperations.fetchAllUsers.rejected, handleRejected)

      .addCase(usersOperations.fetchOneUser.pending, handlePending)
      .addCase(usersOperations.fetchOneUser.fulfilled, (state, action) => {
        state.oneUser = action.payload;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(usersOperations.fetchOneUser.rejected, handleRejected)

      .addCase(usersOperations.fetchAllUsersByQuery.pending, handlePending)
      .addCase(
        usersOperations.fetchAllUsersByQuery.fulfilled,
        (state, action) => {
          state.usersList = action.payload.users;
          state.total = action.payload.total;
          state.isLoading = false;
          state.error = false;
        }
      )
      .addCase(usersOperations.fetchAllUsersByQuery.rejected, handleRejected)

      .addCase(usersOperations.banUser.pending, handlePending)
      .addCase(usersOperations.banUser.fulfilled, (state, action) => {
        state.oneUser.user = action.payload.user;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(usersOperations.banUser.rejected, handleRejected)

      .addCase(usersOperations.unbanUser.pending, handlePending)
      .addCase(usersOperations.unbanUser.fulfilled, (state, action) => {
        state.oneUser.user = action.payload.user;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(usersOperations.unbanUser.rejected, handleRejected);
  },
});

export const { setError, userClearError } = usersSlice.actions;

export default usersSlice.reducer;
