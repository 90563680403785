import { instance } from './instance';
import { setToken } from './instance';

export const registerUser = async data => {
  const { data: result } = await instance.post('/register', data);
  return result;
};

export const getResendInvite = async _id => {
  const data = await instance.post(`/resend-invitation/${_id}`);
  return data;
};

export const loginUser = async data => {
  const { data: result } = await instance.post('/login', data);
  setToken(result.token);
  return result;
};

export const getCurrentUser = async token => {
  try {
    setToken(token);
    const { data } = await instance.get('/current');
    return data;
  } catch (error) {
    setToken();
    throw error;
  }
};

export const updateUserPassword = async data => {
  const { data: result } = await instance.patch('/password', data);
  setToken(result.token);
  return result;
};

export const logoutUser = async () => {
  const { data } = await instance.post('/logout');
  setToken();
  return data;
};
