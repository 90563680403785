import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance, expertInstance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchAllConsultations = createAsyncThunk(
  'consultations/fetch',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('consultations');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchAllConsultationsByQuery = createAsyncThunk(
  'consultations/fetch/filter',
  async (
    { page, limit, userName, expertName, date, status, sort },
    thunkApi
  ) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/consultations', {
        params: {
          page,
          limit,
          sort,
          userName,
          expertName,
          date,
          status,
        },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchAllArchiveConsultationsByQuery = createAsyncThunk(
  'archiveConsultations/fetch/filter',
  async (
    { page, limit, userName, expertName, date, status, sort, complaints },
    thunkApi
  ) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/consultations/archive', {
        params: {
          page,
          limit,
          sort,
          userName,
          expertName,
          date,
          status,
          complaints,
        },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchUserConsultations = createAsyncThunk(
  'userConsultations/fetch',
  async ({ _id, page, limit, sort, expertName, date, status }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(
        `/users/consultations/future/${_id}`,
        {
          params: {
            page,
            limit,
            sort,
            expertName,
            date,
            status,
          },
        }
      );
      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchUserArchiveConsultations = createAsyncThunk(
  'userArchiveConsultations/fetch',
  async (
    { _id, page, limit, sort, expertName, date, status, complaints },
    thunkApi
  ) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.get(
        `/users/consultations/archive/${_id}`,
        {
          params: {
            page,
            limit,
            sort,
            expertName,
            date,
            status,
            complaints,
          },
        }
      );
      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchTherapistConsultations = createAsyncThunk(
  'fetchTherapistConsultations/fetch',
  async ({ _id, page, limit, sort, userName, date, status }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.get(
        `/experts/consultations/future/${_id}`,
        {
          params: {
            page,
            limit,
            sort,
            userName,
            date,
            status,
          },
        }
      );
      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchTherapistArchiveConsultations = createAsyncThunk(
  'fetchTherapistArchiveConsultations/fetch',
  async (
    { _id, page, limit, sort, userName, date, status, complaints },
    thunkApi
  ) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.get(
        `/experts/consultations/archive/${_id}`,
        {
          params: {
            page,
            limit,
            sort,
            userName,
            date,
            status,
            complaints,
          },
        }
      );
      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchOneConsultation = createAsyncThunk(
  'oneConsultation/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);
    try {
      const response = await instance.get(`/consultations/${_id}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const rescheduleConsultations = createAsyncThunk(
  'rescheduleConsultations/patch',
  async ({ id, data }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(
        `/consultations/reschedule/${id}`,
        data
      );

      toasty.toastSuccess('Reschedule successfully');
      return response.data;
    } catch (error) {
      if (
        (error && error.response && error.response.status === 505) ||
        (error && error.response && error.response.status === 500)
      ) {
        return thunkApi.rejectWithValue('Please try again later.');
      }
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const declineConsultation = createAsyncThunk(
  'declineConsultation/patch',
  async ({ _id, reason }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/consultations/fail/${_id}`, {
        reason,
      });

      toasty.toastSuccess('Decline successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const cancelConsultation = createAsyncThunk(
  'cancelConsultation/patch',
  async ({ _id, reason }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/consultations/cancel/${_id}`, {
        reason,
      });

      toasty.toastSuccess('Canceled successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const finishConsultation = createAsyncThunk(
  'finishConsultation/patch',
  async ({ _id, reason }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/consultations/finish/${_id}`, {
        reason,
      });

      toasty.toastSuccess('Finished successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const approveConsultation = createAsyncThunk(
  'approveConsultation/patch',
  async ({ _id, reason, value }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/consultations/approve/${_id}`, {
        reason,
        value,
      });

      toasty.toastSuccess('Approved successfully');

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchSchedule = createAsyncThunk(
  'fetchSchedule/fetch',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/experts/schedule/${_id}`);

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const addDefaultSchedule = createAsyncThunk(
  'defaultSchedule/post',
  async ({ _id, schedule }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post(`/experts/default-schedule/${_id}`, {
        schedule,
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const updateDefaultSchedule = createAsyncThunk(
  'updateDefaultSchedule/put',
  async ({ _id, schedule }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.put(`/experts/default-schedule/${_id}`, {
        schedule,
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const addExpertSchedule = createAsyncThunk(
  'addExpertSchedule/patch',
  async ({ _id, schedule }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    // console.log(_id, schedule);

    try {
      const response = await expertInstance.post(
        `/experts/schedule/?id=${_id}`,
        {
          schedule,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toasty.toastSuccess('Schedule updated successfully');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const consultationsOperations = {
  fetchAllConsultations,
  fetchAllConsultationsByQuery,
  fetchAllArchiveConsultationsByQuery,
  fetchUserConsultations,
  fetchUserArchiveConsultations,
  fetchTherapistConsultations,
  fetchTherapistArchiveConsultations,
  fetchOneConsultation,
  rescheduleConsultations,
  declineConsultation,
  cancelConsultation,
  finishConsultation,
  approveConsultation,
  fetchSchedule,
  addDefaultSchedule,
  updateDefaultSchedule,
  addExpertSchedule,
};

export default consultationsOperations;
