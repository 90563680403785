import { createSlice } from '@reduxjs/toolkit';

import {
  register,
  resendInvite,
  addPassword,
  login,
  current,
  logout,
} from './auth-operations';

const initialState = {
  user: {},
  token: '',
  isLogin: false,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, state => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(register.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(resendInvite.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(resendInvite.fulfilled, state => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(resendInvite.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(addPassword.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addPassword.fulfilled, (state, { payload }) => {
        const { token } = payload;
        state.isLoading = false;
        state.token = token;
        state.error = null;
      })
      .addCase(addPassword.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(login.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        const { admin, token } = payload;
        state.isLoading = false;
        state.user = admin;
        state.token = token;
        state.isLogin = true;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(current.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(current.fulfilled, (state, { payload }) => {
        // console.log(payload);
        const { admin } = payload;
        state.isLoading = false;
        state.user = admin;
        state.isLogin = true;
      })
      .addCase(current.rejected, state => {
        state.isLoading = false;
        state.isLogin = false;
        state.token = '';
      })
      .addCase(logout.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, state => {
        state.isLoading = false;
        state.user = {};
        state.token = null;
        state.isLogin = false;
        state.error = null;
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
});

export const { setError, clearError } = authSlice.actions;

export default authSlice.reducer;
