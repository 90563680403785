import React, { createContext, useContext, useEffect, useRef } from 'react';

import { connectToChat } from '../utils/socketUtils';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({
  token,
  onMessage,
  onReadMessage,
  children,
}) => {
  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = connectToChat(token, onMessage, onReadMessage);

    return () => {
     if (socketRef.current) {
       socketRef.current.disconnect();
     }
    };
  }, [token, onMessage, onReadMessage]);

  if (!socketRef.current) {
    return null;
  }

  return (
    <SocketContext.Provider value={socketRef.current}>
      {children}
    </SocketContext.Provider>
  );
};
