import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../../shared/services/app/instance';
import { setToken } from '../../shared/services/app/instance';

import * as toasty from '../../shared/toastify/toastify';

const fetchAllChatsExpert = createAsyncThunk(
  'chats/experts/fetch',
  async ({ page, limit, name }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/chats/expert/all', {
        params: { page, limit, name },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchAllCustomersChats = createAsyncThunk(
  'chats/customers/fetch',
  async ({ page, limit, name }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/chats/user/all', {
        params: { page, limit, name },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchOneExpertChat = createAsyncThunk(
  'oneExpertChat/fetch',
  async ({ _id, page, limit }, thunkApi) => {
    // console.log(_id, page, limit);

    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/chats/expert/${_id}`, {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      // toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const fetchOneCustomerChat = createAsyncThunk(
  'oneCustomerChat/fetch',
  async ({ _id, page, limit }, thunkApi) => {
    // console.log(_id, page, limit);

    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get(`/chats/user/${_id}`, {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const sendMessageExpert = createAsyncThunk(
  'sendMessageExpert/post',

  async ({ _id, formData }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post(
        `/chats/expert/${_id}/messages`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      // toasty.toastSuccess('Update successfully');
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const sendMessageCustomer = createAsyncThunk(
  'sendMessageCustomer/post',
  async ({ _id, formData }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.post(
        `/chats/user/${_id}/messages`,
        formData
      );
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const deleteExpertChat = createAsyncThunk(
  'deleteExpertChat/delete',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.delete(`/chats/expert/${_id}`, {});
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const deleteCustomerChat = createAsyncThunk(
  'deleteCustomerChat/delete',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.delete(`/chats/user/${_id}`, {});
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const removeExpertMessage = createAsyncThunk(
  'removeExpertMessage/delete',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.delete(`/chats/messages/${_id}`, {});
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const removeCustomerMessage = createAsyncThunk(
  'removeCustomerMessage/delete',
  async (_id, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.delete(`/chats/messages/${_id}`, {});
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const editExpertMassage = createAsyncThunk(
  'editExpertMassage/patch',
  async ({ _id, content }, thunkApi) => {
    const state = thunkApi.getState();

    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(
        `/chats/messages/${_id}`,
        { content }
        // {
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
        // }
      );
      // toasty.toastSuccess('Update successfully');
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const editCustomerMassage = createAsyncThunk(
  'editCustomerMassage/patch',
  async ({ _id, content }, thunkApi) => {
    const state = thunkApi.getState();

    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(`/chats/messages/${_id}`, {
        content,
      });
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const markMessagesAsReadExpert = createAsyncThunk(
  'markMessagesAsReadExpert/patch',
  async ({ _id, messagesIds }, thunkApi) => {
    const state = thunkApi.getState();

    // console.log(_id, messagesIds, 'operation');

    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.patch(
        `/chats/expert/${_id}/messages/mark-as-read`,
        { messagesIds }
        // {
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
        // }
      );
      // toasty.toastSuccess('Update successfully');

      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const markMessagesAsReadCustomer = createAsyncThunk(
  'markMessagesAsReadCustomer/patch',
  async ({ _id, messagesIds }, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;
    setToken(token);

    try {
      const response = await instance.patch(
        `/chats/user/${_id}/messages/mark-as-read`,
        { messagesIds }
      );
      return response.data.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const unreadCountChatsExpert = createAsyncThunk(
  'unread-count/chats/experts/fetch',
  async (params, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/chats/expert/unread-count');
      // console.log(response.data);

      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const unreadCountChatsCustomers = createAsyncThunk(
  'unread-count/chats/users/fetch',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const token = state.auth.token;

    setToken(token);

    try {
      const response = await instance.get('/chats/user/unread-count');
      return response.data;
    } catch (error) {
      toasty.toastError(error.response.data.message);
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

const chatsOperations = {
  fetchAllChatsExpert,
  fetchAllCustomersChats,
  fetchOneExpertChat,
  fetchOneCustomerChat,
  sendMessageExpert,
  sendMessageCustomer,
  deleteExpertChat,
  deleteCustomerChat,
  removeExpertMessage,
  removeCustomerMessage,
  editExpertMassage,
  editCustomerMassage,
  markMessagesAsReadExpert,
  markMessagesAsReadCustomer,
  unreadCountChatsExpert,
  unreadCountChatsCustomers,
};

export default chatsOperations;
